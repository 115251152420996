<template>
  <b-card-actions
      ref="first-card"
      class="list-card"
      @refresh="refreshStop('first-card')"
  >
    <b-row class="mt-50 mb-5">
      <div class="card-header-row" :style="{backgroundColor: $store.state.colors.headerRowColor}">
        <b-col cols="12" class="d-flex justify-content-between">
          <h4 class="text-white d-inline-block card-title">{{ title }}</h4>
          <div>
            <b-dropdown
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Listeler"
                dropleft
                class="row-btn mr-50"
                variant="info"
            >
              <b-dropdown-item variant="success" :to="`/detayliTablo/${vardiyaID}`">
                <font-awesome-icon icon="table-list" class="mr-50"/>
                Detaylı Gelir Listesi
              </b-dropdown-item>
              <b-dropdown-item variant="warning" :to="`/giderListesi/${vardiyaID}`">
                <font-awesome-icon icon="table-list" class="mr-50"/>
                Gider Listesi
              </b-dropdown-item>
              <b-dropdown-item variant="primary" :to="`/adisyonListesi/${vardiyaID}`">
                <font-awesome-icon icon="table-list" class="mr-50"/>
                Adisyon Listesi
              </b-dropdown-item>
            </b-dropdown>
            <b-button
                v-if="vardiyaID !== '0' && vardiyaID !== 0"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                v-b-modal.gelir-kaydi-id
                variant="success"
                class="row-btn"
            >
              <font-awesome-icon icon="plus" class="mr-50"/>
              Gelir Kaydı
            </b-button>
          </div>
        </b-col>
      </div>
    </b-row>
    <gelir-kaydi-i-d :vardiyaID="vardiyaID" @finished="paymentFinished(3)"/>
    <b-button
        v-if="vardiyaID !== '0' && vardiyaID !== 0"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-modal.gelir-kaydi-id
        variant="success"
        class="mobile-row-btn mr-50"
    >
      <font-awesome-icon icon="plus" class="mr-50"/>
      Gelir Kaydı
    </b-button>
    <b-dropdown
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        text="Listeler"
        dropright
        class="mobile-row-btn mr-50"
        variant="info"
    >
      <b-dropdown-item variant="success" :to="`/detayliTablo/${vardiyaID}`">
        <font-awesome-icon icon="table-list" class="mr-50"/>
        Detaylı Gelir Listesi
      </b-dropdown-item>
      <b-dropdown-item variant="warning" :to="`/giderListesi/${vardiyaID}`">
        <font-awesome-icon icon="table-list" class="mr-50"/>
        Gider Listesi
      </b-dropdown-item>
      <b-dropdown-item variant="primary" :to="`/adisyonListesi/${vardiyaID}`">
        <font-awesome-icon icon="table-list" class="mr-50"/>
        Adisyon Listesi
      </b-dropdown-item>
    </b-dropdown>
    <hr>
    <b-row class="justify-content-evenly">
      <b-col
          xl="2"
          md="3"
          sm="4"
      >
        <b-media no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                variant="success"
                class="pull-up"
            >
              <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-nowrap">
              {{
                Number(vardiya.finans.ciro)
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
            <strong class="mb-0">
              Toplam Ciro
            </strong>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col
          xl="2"
          md="3"
          sm="4"
          class="my-lg-0 my-md-0 my-sm-0 my-1"
      >
        <b-media no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                variant="info"
                class="pull-up"
            >
              <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-nowrap">
              {{
                Number(vardiya.finans.nakit)
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
            <strong class="mb-0">
              Nakit Gelir
            </strong>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col
          xl="2"
          md="3"
          sm="4"
      >
        <b-media no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                variant="secondary"
                class="pull-up bg-primary bg-lighten-2"
            >
              <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-nowrap">
              {{
                Number(vardiya.finans.krediKarti)
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
            <strong class="mb-0">
              Kredi Kartı
            </strong>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col
          xl="2"
          md="3"
          sm="4"
          class="my-lg-0 my-md-0 my-sm-1 my-1"
      >
        <b-media no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                variant="secondary"
                class="pull-up bg-success bg-darken-2"
            >
              <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-nowrap">
              {{
                Number(vardiya.finans.cariKredi)
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
            <strong class="mb-0">
              Cari Kredi
            </strong>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col
          xl="2"
          md="3"
          sm="4"
          class="my-xl-0 my-lg-1 my-md-1 my-sm-1 my-1 mt-md-1"
      >
        <b-media no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                variant="secondary"
                class="pull-up bg-info bg-darken-2"
            >
              <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-nowrap">
              {{
                Number(vardiya.finans.havale)
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
            <strong class="mb-0">
              Havale
            </strong>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col
          xl="2"
          md="3"
          sm="4"
          class="my-xl-0 my-lg-1 my-md-1 my-sm-1 my-1 mt-md-1"
      >
        <b-media no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                variant="secondary"
                class="pull-up bg-warning"
            >
              <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-nowrap">
              {{
                Number(vardiya.finans.krediTahsilat)
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
            <strong class="mb-0">
              Kredi Tahsilatları
            </strong>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col
          xl="2"
          md="3"
          sm="4"
          class="my-1"
      >
        <b-media no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                variant="secondary"
                class="pull-up bg-danger"
            >
              <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-nowrap">
              {{
                Number(vardiya.finans.gider)
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
            <strong class="mb-0">
              Vardiya Giderleri
            </strong>
          </b-media-body>
        </b-media>
      </b-col>
      <b-col
          xl="2"
          md="3"
          sm="5"
          class="my-1"
      >
        <b-media no-body>
          <b-media-aside
              class="mr-2"
          >
            <b-avatar
                size="48"
                variant="secondary"
                class="pull-up bg-success bg-darken-1"
            >
              <font-awesome-icon icon="arrow-trend-up" class="fa-2x"/>
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h4 class="font-weight-bolder mb-0 text-nowrap">
              {{
                Number(vardiya.finans.netKasa)
              }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </h4>
            <strong class="mb-0">
              Net Kasa
            </strong>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>
    <hr class="mt-3">
    <b-tabs class="mt-1">
      <b-tab active>
        <template #title>
          <font-awesome-icon icon="money-bill-trend-up"/>
          <span>Gelir Kayıtları</span>
        </template>
        <b-table
            striped
            hover
            empty-text="Kayıt Bulunamadı"
            show-empty
            responsive
            class="position-relative gelir-kayitlari-table mt-1"
            :items="gelirList"
            :fields="fields1"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
          <template #cell(user)="data">
            <font-awesome-icon icon="user"/>
            <p class="d-inline-block ml-50 m-0">{{ data.item.userName }}</p>
          </template>
          <template #cell(departmanAdi)="data">
            <p class="d-inline-block font-weight-bolder ml-50 m-0"
               :class="{'text-primary' : data.item.adisyonID !== '0'}"
               @click="data.item.adisyonID !=='0' ? goAdisyonDetay(data.item):null"
            >
              {{ data.item.departmanAdi }}
              {{ data.item.departmanAdi === 'Adisyon' ? `(${data.item.masaAdi})` : '' }}</p>
          </template>
          <template #cell(odemeTuru)="data">
            {{ getOdemeTuru(data.item.odemeTuru) }}
          </template>
          <template #cell(gelir)="data">
            <strong v-if="data.item.gelir" class="ml-50">
              {{ data.item.gelir }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </strong>
          </template>
          <template #cell(fisNo)="data">
            <p v-if="data.item.fisNo" class="m-0">
              # {{ data.item.fisNo }}
            </p>
          </template>
          <template #cell(aciklama)="data">
            <p class="m-0">{{ data.item.aciklama }}</p>
          </template>
          <template #cell(kayitTarih)="data">
            <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
          </template>
          <template #cell(gelirID)="data">
            <b-button
                v-if="data.item.tahsilatID ==='0'"
                v-b-modal.gelir-guncelle
                variant="gradient-primary"
                class="btn-icon rounded-circle mr-50"
                @click="gelirDataSet(data.item)"
            >
              <font-awesome-icon icon="pen"/>
            </b-button>
            <b-button
                v-if="data.item.tahsilatID ==='0'"
                variant="gradient-danger"
                class="btn-icon rounded-circle mr-50"
                @click="itemRemove(data.item,1)"
            >
              <font-awesome-icon icon="trash"/>
            </b-button>
          </template>
        </b-table>
        <b-modal
            id="gelir-guncelle"
            ok-variant="success"
            ok-title="Kaydet"
            cancel-title="Kapat"
            modal-class="modal-success"
            centered
            title="Yeni Tutar"
            @ok="gelirUpdate()"
            @hide="gelirDataSet()"
        >
          <b-form-group
              label="Yeni Tutar"
              label-for="basicInput"
          >
            <b-form-input
                id="basicInput"
                v-model="gelirUpdateData.tutar"
                v-numeric-only
                autocomplete="off"
                @keyup.enter="gelirUpdate()"
            />
          </b-form-group>
        </b-modal>
      </b-tab>
      <b-tab @click="giderListesi()">
        <template #title>
          <font-awesome-icon icon="arrow-trend-down"/>
          <span>Gider Kayıtları</span>
        </template>
        <b-table
            striped
            hover
            responsive
            class="position-relative gelir-kayitlari-table mt-1"
            :items="giderList"
            empty-text="Kayıt Bulunamadı"
            show-empty
            :fields="fields2"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
          <template #cell(userName)="data">
            <font-awesome-icon icon="user"/>
            <p class="d-inline-block ml-50 m-0">{{ data.item.userName }}</p>
          </template>
          <template #cell(tutar)="data">
            <strong v-if="data.item.tutar" class="ml-50">
              {{ data.item.tutar }}
              <font-awesome-icon icon="turkish-lira-sign"/>
            </strong>
          </template>
          <template #cell(aciklama)="data">
            <p class="m-0">{{ data.item.aciklama }}</p>
          </template>
          <template #cell(fisNo)="data">
            <p class="m-0">{{ data.item.fisNo }}</p>
          </template>
          <template #cell(kayitTarih)="data">
            <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
          </template>
          <template #cell(giderID)="data">
            <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle mr-50"
                @click="itemRemove(data.item,2)"
            >
              <font-awesome-icon icon="trash"/>
            </b-button>
          </template>
        </b-table>
      </b-tab>
      <b-tab @click="tahsilatListesi()">
        <template #title>
          <font-awesome-icon icon="bars"/>
          <span>Tahsilat Listesi</span>
        </template>
        <b-table
            striped
            hover
            empty-text="Kayıt Bulunamadı"
            show-empty
            responsive
            class="position-relative gelir-kayitlari-table mt-1"
            :items="tahsilatList"
            :fields="fields3"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
        >
          <template #cell(userName)="data">
            <font-awesome-icon icon="user"/>
            <p class="d-inline-block ml-50 m-0">
              {{ data.item.userName }}
            </p>
          </template>
          <template #cell(unvan)="data">
            <p class="font-weight-bolder m-0">{{ data.item.unvan }}</p>
          </template>
          <template #cell(isim)="data">
            <p class="m-0">{{ data.item.isim }}</p>
          </template>
          <template #cell(tutar)="data">
            <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ data.item.tutar }} TL</p>
          </template>
          <template #cell(tahsilatTuru)="data">
            <p class="d-inline-block m-0 font-weight-bolder mr-50">{{ getOdemeTuru(data.item.tahsilatTuru) }}</p>
          </template>
          <template #cell(aciklama)="data">
            <p class="m-0">{{ data.item.aciklama }}</p>
          </template>
          <template #cell(kayitTarih)="data">
            <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
          </template>
          <template #cell(kasaID)="data">
            <b-button
                variant="gradient-danger"
                class="btn-icon rounded-circle mr-50"
                @click="itemRemove(data.item,3)"
            >
              <font-awesome-icon icon="trash"/>
            </b-button>
          </template>
        </b-table>
      </b-tab>
      <b-tab @click="adisyonListesi()">
        <template #title>
          <font-awesome-icon icon="circle-dot"/>
          <span>Adisyon Listesi</span>
        </template>
        <b-table
            striped
            hover
            empty-text="Kayıt Bulunamadı"
            show-empty
            responsive
            class="position-relative gelir-kayitlari-table mt-1"
            :items="adisyonList"
            :fields="fields4"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            @row-clicked="goAdisyonDetay"
        >
          <template #cell(userName)="data">
            <font-awesome-icon icon="user"/>
            <p class="d-inline-block ml-50 m-0">
              {{ data.item.userName }}
            </p>
          </template>
          <template #cell(toplamTutar)="data">
            <p class="m-0 d-inline-block font-weight-bolder mr-50">{{ data.item.toplamTutar }}</p>
            <font-awesome-icon icon="turkish-lira-sign"/>
          </template>
          <template #cell(adisyonDurum)="data">
            <b-badge :variant="data.item.adisyonDurum === '0'?'light-primary':'light-info'">
              {{ data.item.adisyonDurum === '0' ? 'Kapalı' : 'Açık' }}
            </b-badge>
          </template>
          <template #cell(durum)="data">
            <b-badge :variant="data.item.durum === '0'?'light-warning':'light-success'">
              {{ data.item.durum === '0' ? 'Bekleniyor' : 'Tamamlandı' }}
            </b-badge>
          </template>
          <template #cell(masaAdi)="data">
            <p class="m-0">{{ data.item.masaAdi }}</p>
          </template>
          <template #cell(kayitTarih)="data">
            <p class="m-0">{{ data.item.kayitTarih.slice(0, 16) }}</p>
          </template>
        </b-table>
      </b-tab>
    </b-tabs>
  </b-card-actions>
</template>

<script>
import {
  BDropdown,
  BDropdownItem,
  BTable,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BTabs,
  BTab,
  BButton,
  BRow,
  BCol,
  BModal,
  BListGroup,
  BListGroupItem,
  BFormGroup,
  BFormInput,
  BCardText,
  BFormCheckbox,
  BFormRadio,
  BSpinner,
  VBTooltip,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'
import axios from 'axios'
import GelirKaydi from '@/views/financeManager/gelirKaydi.vue'
import GelirKaydiID from '@/views/financeManager/gelirKaydiID.vue'

export default {
  components: {
    BDropdown,
    BDropdownItem,
    GelirKaydiID,
    GelirKaydi,
    BTable,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BBadge,
    BSpinner,
    ToastificationContent,
    BFormRadio,
    BFormCheckbox,
    BCardText,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BModal,
    BCardActions,
    BRow,
    BCol,
    BButton,
    BTabs,
    BTab,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      gelirUpdateData: {
        gelirID: null,
        tutar: null,
      },
      vardiyaID: this.$route.params.id,
      fields1: [
        {
          key: 'user',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'departmanAdi',
          label: 'Departman',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'odemeTuru',
          label: 'Ödeme Türü',
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'gelir',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'fisNo',
          label: 'Fiş No',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklama',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
        {
          key: 'gelirID',
          label: 'İşlemler',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      fields2: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklama',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '35%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '35%' },

        },
        {
          key: 'fisNo',
          label: 'Fiş No',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
        {
          key: 'giderID',
          label: 'İşlemler',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      fields3: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'unvan',
          label: 'Unvan',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'isim',
          label: 'isim',
          thClass: ['text-center'],
          tdClass: ['text-center'],
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tutar',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'tahsilatTuru',
          label: 'Tahsilat Türü',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'aciklama',
          label: 'Açıklama',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '25%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },

        },
        {
          key: 'kasaID',
          label: '',
          sortable: false,
          thClass: ['text-right'],
          tdClass: ['text-right'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
      ],
      fields4: [
        {
          key: 'userName',
          label: 'İşlem Sahibi',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'toplamTutar',
          label: 'Tutar',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '20%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '20%' },
        },
        {
          key: 'adisyonDurum',
          label: 'Açık / Kapalı',
          thClass: ['text-center'],
          tdClass: ['text-center'],
          sortable: true,
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'durum',
          label: 'Ödeme Durumu',
          sortable: true,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '10%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '10%' },
        },
        {
          key: 'masaAdi',
          label: 'Masa',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '15%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '15%' },

        },
        {
          key: 'kayitTarih',
          label: 'Tarih',
          sortable: false,
          thClass: ['text-center'],
          tdClass: ['text-center'],
          thStyle: {
            width: '25%',
            backgroundColor: store.state.colors.tableHeaderColor || '#3c6e71',
            color: 'white',
            border: 'none',
          },
          tdStyle: { width: '25%' },

        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
    }
  },
  computed: {
    title() {
      return 'Restoran Vardiya Özeti'
    },
    gelirList: () => store.getters.GET_VARDIYA_DETAY?.finans?.gelirListesi,
    giderList: () => store.getters.GET_GIDER_LIST,
    tahsilatList: () => store.getters.GET_TAHSILAT_LIST,
    adisyonList: () => store.getters.GET_ADISYON_LIST,
    vardiya() {
      return {
        vardiyaDetay: store.getters.GET_VARDIYA_DETAY,
        finans: {
          ciro: store.getters.GET_VARDIYA_DETAY.finans?.ciro || 0,
          nakit: store.getters.GET_VARDIYA_DETAY.finans?.nakit || 0,
          krediKarti: store.getters.GET_VARDIYA_DETAY.finans?.krediKarti || 0,
          cariKredi: store.getters.GET_VARDIYA_DETAY.finans?.cariKredi || 0,
          havale: store.getters.GET_VARDIYA_DETAY.finans?.havale || 0,
          krediTahsilat: store.getters.GET_VARDIYA_DETAY.finans?.krediTahsilat || 0,
          gider: store.getters.GET_VARDIYA_DETAY.finans?.gider || 0,
          netKasa: store.getters.GET_VARDIYA_DETAY.finans?.netKasa || 0,
        },
      }
    },
  },
  watch: {
    '$route.params.id': function () {
      this.vardiyaID = this.$route.params.id
      this.vardiyaData()
      this.giderListesi()
      this.tahsilatListesi()
      this.adisyonListesi()
    },
  },
  created() {
    this.vardiyaData()
  },
  beforeDestroy() {
    store.commit({
      type: 'SET_VARDIYA_DETAY',
      action: 0,
    })
    store.commit({
      type: 'SET_ADISYON_LIST',
      action: 0,
    })
    store.commit({
      type: 'SET_TAHSILAT_LIST',
      action: 0,
    })
  },
  methods: {
    goAdisyonDetay: event => router.push(`/order/${event.masaID}/${event.adisyonID}`),
    adisyonListesi() {
      const fd = this.postSchema()
      fd.set('methodName', 'adisyonListesi')
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_ADISYON_LIST',
              action: 1,
              list: response.adisyonList,
            })
          })
    },
    tahsilatListesi() {
      const fd = this.postSchema()
      fd.set('methodName', 'tahsilatListesi')
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_TAHSILAT_LIST',
              action: 1,
              list: response.tahsilatList,
            })
          })
    },
    giderListesi() {
      const fd = this.postSchema()
      fd.set('methodName', 'giderListesi')
      axios.post('', fd)
          .then(response => {
            store.commit({
              type: 'SET_GIDER_LIST',
              action: 1,
              list: response.giderList,
            })
          })
    },
    itemRemove(item, how) {
      if (how === 1) {
        this.$swal({
          title: 'Gelir Silinecektir?',
          text: 'Bu işlem geri alınamaz',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet Sil',
          cancelButtonText: 'Vazgeç',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const fd = this.postSchema()
                fd.set('methodName', 'gelirSil')
                fd.set('gelirID', item.gelirID)
                axios.post('', fd)
                    .then(response => {
                      if (response.result.status === 200) {
                        this.vardiyaData()
                      }
                    })
              }
            })
      } else if (how === 2) {
        this.$swal({
          title: 'Gider Kaydı Silinecektir?',
          text: 'Bu işlem geri alınamaz',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet Sil',
          cancelButtonText: 'Vazgeç',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const fd = this.postSchema()
                fd.set('methodName', 'giderSil')
                fd.set('giderID', item.giderID)
                axios.post('', fd)
                    .then(response => {
                      if (response.result.status === 200) {
                        this.vardiyaData()
                        this.giderListesi()
                      }
                    })
              }
            })
      } else if (how === 3) {
        this.$swal({
          title: 'Tahsilat Silinecektir?',
          text: 'Bu işlem geri alınamaz',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Evet Sil',
          cancelButtonText: 'Vazgeç',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                const fd = this.postSchema()
                fd.set('serviceName', 'cari')
                fd.append('methodName', 'kasaSil')
                fd.append('cariKartID', item.cariKartID)
                fd.append('kasaID', item.kasaID)
                axios.post('', fd)
                    .then(response => {
                      if (response.result.status === 200) {
                        this.tahsilatListesi()
                        this.vardiyaData()
                      }
                    })
              }
            })
      }
    },
    gelirUpdate() {
      const fd = this.postSchema()
      fd.set('methodName', 'gelirDuzenle')
      fd.set('gelirID', this.gelirUpdateData.gelirID)
      fd.set('tutar', this.gelirUpdateData.tutar.replace(/,/g, '.'))
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              this.$bvModal.hide('gelir-guncelle')
              this.vardiyaData()
              this.gelirDataSet()
            }
          })
    },
    paymentFinished(modal) {
      if (modal === 1) {
        this.$bvModal.hide('gelir-kaydi')
        this.vardiyaData()
      } else if (modal === 3) {
        this.$bvModal.hide('gelir-kaydi-id')
        this.vardiyaData()
      } else {
        this.$bvModal.hide('gider-kaydi')
        this.vardiyaData()
      }
    },
    gelirDataSet(item) {
      this.gelirUpdateData.gelirID = item?.gelirID || null
      this.gelirUpdateData.tutar = item?.gelir || null
    },
    getOdemeTuru: tur => (tur === '1' ? 'Nakit' : tur === '2' ? 'Havale' : tur === '3' ? 'Kredi Kartı' : tur === '4' ? 'Cari Kredisi' : 'Nakit'),
    vardiyaData() {
      const fd = this.postSchema()
      fd.set('methodName', 'vardiyaOzeti')
      axios.post('', fd)
          .then(response => {
            if (response.result.status === 200) {
              store.commit({
                type: 'SET_VARDIYA_DETAY',
                action: 1,
                list: response.vardiyaData,
              })
            }
          })
    },
    postSchema() {
      const fd = new FormData()
      fd.append('crm_token', store.state.userToken || localStorage.getItem('restoranUserToken'))
      fd.append('serviceName', 'vardiya')
      if (this.vardiyaID !== '0' && this.vardiyaID !== 0) {
        fd.append('vardiyaID', this.vardiyaID)
      }
      return fd
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
}
</script>
<style>
.pull-up {
  transition: 200ms;
}

.gelir-kayitlari-table .table td {
  padding: 0.22rem 2rem !important;
}

.gider-kayitlari-table .table td {
  padding: 0.22rem 2rem !important;
}

</style>
